.navigation {
    width: 690px;
    height: 20px;
    float: right;

    button {
        cursor: pointer;
        border: none;
        background: none;

        &:disabled {
            cursor: default !important;

            svg path{
                fill: #cccccc;
            }
        }

        svg path{
            fill: #83ABB7;
        }
    }

    .left-arrow {
        position: relative;
        width: 9px;
        height: 17px;
        float: left;
    }

    .right-arrow {
        position: relative;
        width: 9px;
        height: 17px;
        float: right;
    }
}
