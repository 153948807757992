.chat-history {
    width: 469px;
    margin: 0 20px 0 0;
    border-right: #e3dbdb 1px solid;

    ul.rc-pagination {
        position: relative;
        font-size: 38px;
        color: #83abb7;
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: 50px;

        li.rc-pagination-item {
            display: none;
        }

        li.rc-pagination-total-text {
            position: absolute;
            margin: 0 0 0 17%;
            width: 250px;
            padding: 0;
            color: #98b9c3;
            font-family: Mukta;
            font-size: 14px;
            font-weight: 400;
            line-height: 44px;
        }

        li:last-child {
            float: right;
        }

        .rc-pagination-prev {
            cursor: pointer;
            min-width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border: none;
            margin: 5px 0;
            float: left;

            a:after{
                content: "‹";
                display: block;
            }
        }

        .rc-pagination-next {
            cursor: pointer;
            min-width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border: none;
            margin: 5px 0;

            a:after{
                content: "›";
                display: block;
            }
        }

        .rc-pagination-disabled .rc-pagination-jump-prev,
        .rc-pagination-disabled .rc-pagination-jump-next {
            pointer-events: none;
        }

        .rc-pagination-disabled a {
            color: #ccc;
            cursor: default;
        }
        .rc-pagination-disabled .rc-pagination-item,
        .rc-pagination-disabled .rc-pagination-prev,
        .rc-pagination-disabled .rc-pagination-next {
            cursor: not-allowed;
        }

        .rc-pagination-item-link {
            color: #83abb7;
            text-decoration: none;
        }
    }
}

.company-item {
    height: 68px;
    background-color: #f7f7f7;
    margin: 0 0 5px 0;
    text-align: left;
    font-family: Mukta;
    cursor: pointer;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    position: relative;

    svg {
        top: 55%;
        right: 5px;
        position: absolute;
        cursor: pointer;
        display: none;
    }

    &:hover svg{
        display: block;
    }
}

.company-item:hover, .active {
    background: #e9f2f4;
}

.company-item h6 {
    color: #050505;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 10px 25px 0 32px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.4em;
    white-space: nowrap;
}

.company-item p {
    color: #050505;
    font-size: 16px;
    font-weight: 400;
    margin: 6px 0 0 32px;
    padding: 0 30px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.4em;
    white-space: nowrap;
}

.export-link {
    display: block;
    height: 20px;
    margin: 0 0 10px 0;
    width: 100%;
    text-align: right;
}

.export-link a {
    text-decoration: none;
    color: #83abb7;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.45px;
    padding: 0 10px 0 0;
}


