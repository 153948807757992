.new-password {
  font-family: Mukta;
  background-color: #344865;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  .body {
    text-align: center;
    width: 600px;
    margin: 200px auto;
    display: flex;
    flex-flow: row wrap;
  }

  .forget-password__form {
    margin-top: 52px;
  }

  .field {
    margin: 0 0 17px 0;
    text-align: left;

    &__error {
      height: 15px;

      .errors {
        color: #eb7070;
        text-align: left;
        font-size: 12px;
        padding: 0 0 8px 0;
        height: 15px;
      }
    }
  }

  .form-group {
    text-align: left;
    margin-top: 42px;
  }

  h3 {
    color: white;
  }

  p {
    width: 320px;
    display: block;
    color: #e3dbdb;
  }

  .success-info {
    text-align: left;
    width: 320px;
    margin-top: auto;
    margin-bottom: auto;

    h2 {
      color: white;
      line-height: 30px;
      margin-top: 48px;
      margin-bottom: 25px;
    }

    p {
      color: grey;
      line-height: 24px;
    }
  }
}

