@import url('https://fonts.googleapis.com/css?family=Mukta');

.Accounts {
  table {
    border: solid 1px #DDEEEE;
    border-collapse: collapse;
    border-spacing: 0;
    font: normal 13px Mukta, sans-serif;
    margin: 50px auto;

    thead th {
      background-color: #83abb7;
      border: solid 1px #83abb7;
      color: white;
      padding: 10px;
      text-align: left;
    }

    tbody td {
      border: solid 1px #DDEEEE;
      color: #333;
      padding: 10px;
      text-shadow: 1px 1px 1px #fff;
    }
  }
}
