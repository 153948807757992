.apikeys {

  .title {
    color: #050505;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }

  .apikey {
    padding: 20px;
  }
}
