@import url('https://fonts.googleapis.com/css?family=Mukta');

.react-autosuggest__container {
}

.react-autosuggest__input {
    width: 330px;
    border: 0;
    border-bottom: #e3dbdb solid 1px;
    background-color: #fbfbfb;
    height: 30px;
    padding: 10px 20px;
    font-family: Mukta;
    font-size: 18px;
    font-style: italic;
    padding-right: 70px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 440px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.suggestion-item {
    text-align: left;

    small{
        clear: both;
        display: block;
    }
}

.search-bar {
    width: 100%;
    height: 50px;
    position: relative;
    margin: 50px 0 80px 0;

    .bar {
        display: flex;
        position: absolute;
        left: 30%;
        bottom:5px;
    }

    button {
        width: 156px;
        height: 49px;
        border-radius: 24px;
        background-color: #83abb7;
        font-size: 18px;
        margin: 0 0 0 -2px;
        border: none;
        color: white;
        cursor: pointer;

        &:hover {
            background-color: #94c4d2;
        }
    }

    input {
        color: #bdbdbd;
    }

    .search-instruction {
        top: 100px;
        text-align: left;
        width: 100%;
        font-size: 20px;
        font-family: Mukta;
        color: #050505;
        display: inline-block;

        svg {
            margin: 45px 0 30px 295px;
        }

        div {
            width: 400px;
            display: inline-block;
            margin: 0 0 0 25px;
            font-style: italic;
        }
    }

    .error .react-autosuggest__input{
        border-bottom: 1px solid #eb7070;
    }
    .errors {
        display: flex;
        position: absolute;
        left: 30%;
        bottom:-5px;
        color: #eb7070;
        text-align: left;
        font-size: 12px;
        font-style: italic;
    }
}
