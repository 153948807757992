@import url('https://fonts.googleapis.com/css?family=Mukta');

body {
    margin: 0;
    height: 100%;
    background-color: #fbfbfb;
    font-family: Mukta;
}

#root {
    height: 100%;
}

.internalServer{
    width: 800px;
    margin: 50px auto;

    font-size: 24px;
    text-align: center;
    color: black;
}

.internalServer img{
    display: block;
    margin: 0 auto 50px auto;
}

.internalServer p{
    font-size: 18px;
}
