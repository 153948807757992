.modal-list {
    a.open-modal {
        color: #83abb7;
        font-size: 18px;
        font-weight: 700;
        padding: 0 10px 0 0;
        line-height: 50px;
        margin: 0 0 0 -70px;
        font-family: Mukta;
    }
}

.Modal {
    position: absolute;
    width: 450px;
    min-height: 380px;
    height: auto;
    top: 30%;
    left: 50%;
    right: auto;
    bottom: auto;
    padding: 0 10px;
    background-color: #F7F7F7;
    border: 1px solid #E9F2F4;
    transform: translate(-50%, -50%);
    overflow: hidden;
    font-family: Mukta;

    h3 {
        color: #050505;
        font-size: 24px;
    }

    textarea {
        width: 95%;
        height: 200px;
        background: #ffffff;
        font-family: Mukta;
        color: #BDBDBD;
        border: none;
        padding: 10px;
    }

    .buttons {
        text-align: right;
        margin: 20px 0 20px 0;

        a {
            cursor: pointer;
            text-decoration: underline;
            color: #bdbdbd;
        }
    }

    button {
        width: 100px;
        height: 35px;
        margin: 0 0 0 10px;
        border-radius: 24px;
        background-color: #83abb7;
        font-size: 18px;
        border: none;
        color: white;
        cursor: pointer;

        &:disabled {
            background-color: #bdbdbd;
        }
    }

    span {
        display: block;
    }

    .errors {
        color: #B85767;
        font-size: 14px;
    }
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: none;
}
