@import url('https://fonts.googleapis.com/css?family=Mukta');

.admin-history {

  .admin-history-wrapper {
    min-width: 1368px;

    .ReactTable {
      margin: 50px 0 0 0;
    }
  }
}
