@import url('https://fonts.googleapis.com/css?family=Mukta');

.App-body {
  background-color: #fbfbfb;
  min-height: 100%;
  height: auto;
  position: relative;

  .App {
    text-align: center;
    width: 1180px;
    margin: 0 auto;
    padding: 0 0 40px 0;

    .right-panel {
      width: 691px;
      display: block;
    }
  }

  .App-header {
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 120px;

    .user-section {
      float: right;
      color: #344865;
      font-family: "Mukta";
      font-size: 14px;
      font-weight: 400;
      margin: 56px 0 0 0;

      a.logout {
        width: 36px;
        height: 25px;
        display: inline-block;
        margin: 0;
        position: relative;
        text-align: left;
      }

      ul {
        display: inline-block;
        padding: 0 20px 0 0;
        margin: 0;

        a {
          color: #344865;
        }

        li {
          display: inline-block;
          padding: 0 10px;
        }
      }

      span {
        margin: 0 16px 0 0;
        display: inline-block;
        margin: 4px 10px 0 0;
      }

      img {
        position: absolute;
        top: 5px;
      }
    }
  }

  .App-link {
    color: #09d3ac;
  }

  body {

  }

  .body {
    display: flex;
    flex-wrap: wrap;
    min-height: 200px;
  }

  .first-section {
    width: 100%;
    text-align: right;
  }

  .second-section {
    display: flex;
  }

  img.logo {
    margin: 30px 0 0 0;
    float: left;
  }

  .dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none;
  }
}
