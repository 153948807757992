@import url('https://fonts.googleapis.com/css?family=Mukta');

body {
}

.login{
  background-color: #344865;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &__body {
    &--content {
      display: flex;
      flex-wrap: wrap;
      width: 322px;

      &__links {
        margin: 20px 0 0 0;
        width: 100%;
        text-align: left;

        a.forgot-password {
          flex-grow: 3;
          margin: 0;
          color: #e3dbdb;
          text-decoration: underline
        }

        a.register-link {
          flex-grow: 3;
          margin: 0 0 0 10px;
          color: #e3dbdb;
          text-decoration: underline
        }
      }
    }
  }

  .break{
    flex-basis: 100%;
    width: 0px;
    height: 0px;
    overflow: hidden;
  }
}

