@import url('https://fonts.googleapis.com/css?family=Mukta');
@import "~react-loader-spinner/dist/loader/css/react-spinner-loader.css";

$yellow: #c4b25b;
$yellow-hover: #dac76b;

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

html {
  height: 100%;

  body {
    margin: 0;
    height: 100%;
    font-family: -apple-system, Mukta, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    #root {
      height: 100%;
    }

    .react-tooltip-theme {
      background-color: #344865 !important;
      color: #fbfbfb !important;
      font-size: 12px !important;
      font-family: Mukta;
      max-width: 300px;
      padding: 7px 10px;

      &.place-top {
        &:after {
          border-top-color: #344865 !important;
          border-top-style: solid !important;
          border-top-width: 6px !important;
        }
      }
    }
  }
}

.login-panel {
  text-align: center;
  width: 580px;
  margin: 200px auto;
  display: flex;
  justify-content: space-between;

  &__logo {
    margin-top: 42px;
  }
}

.input-label {
  color: #e3dbdb;
  font-family: Mukta;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.4px;
  display: block;
  text-align: left;
}
.input-box {
  position: relative;

  &::after {
    content: "";
    width: 0;
    position: absolute;
    height: 2px;
    left: 0;
    bottom: 3px;
    background-color: #eb7070;
    @include transition(width 0.3s ease);
  }

  &.error {
    &::after {
      width: 100%;
    }
  }
}

.input-field {
  margin: 9px 0 3px 0;
  padding-bottom: 5px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: #e3dbdb 2px solid;
  background: transparent;
  width: 320px;
  color: white;
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  outline: none;
}

.btn {
  font-family: Mukta;
  background-color: $yellow;
  font-size: 22px;
  padding: 6px 56px;
  max-width: 100%;
  @include transition(0.3s ease);
  margin: 0;
  border-radius: 24px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background-color: $yellow-hover
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}