body {
}

.register{
  background-color: #344865;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  .success-info {
    text-align: left;
    width: 340px;
    height: 193px;
    margin-top: auto;
    margin-bottom: auto;

    h2 {
      color: white;
      margin-top: 21px;
      margin-bottom: 25px;
    }

    p {
      color: grey;
    }
  }

  .user-form {

    &__terms {
      display: block;
      color: #e3dbdb;
      font-family: Mukta;
      font-size: 15px;
      margin: 19px 0 0 0;

      a {
        color: #e3dbdb;
        font-style: italic;
        margin-left: 4px;
        outline: none;
        display: inline-block;
      }
    }
  }
}
