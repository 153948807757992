@import url('https://fonts.googleapis.com/css?family=Mukta');

.profile {
  .error-message {
    padding: 20px;

    strong {
      font-size: 24px;
    }

    p {
      font-size: 18px;
    }
  }
}

.company-profile {
  font-family: Mukta;

  svg {
    .social-hover {
      fill: #83ABB7;
    }

    &:hover .social-hover {
      fill: #344865;
    }
  }

  strong {
    font-family: Mukta;
    margin: 0 0 20px 0;
    display: block;
  }

  .logo {
    clear: both;
    min-height: 150px;
  }

  .title {
    color: #050505;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }

  .url {
    color: #bdbdbd;
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 20px 0;

    a {
      color: #bdbdbd;
    }
  }

  .socials {
    width: 100%;
    text-align: center;
    margin: 0 0 60px 0;

    a {
      font-size: 33px;
      padding: 0 10px 0 0;
    }
  }

  ul.additional-info {
    list-style-image: url("tick.png");
    font-family: Mukta;
    font-size: 18px;
    font-weight: 400;
    width: 130px;
    margin: 0;
    padding: 0 0 0 20px;

    li {
      padding: 0 0 17px 0;

      a {
        color: #050505;
      }
    }
  }

  .show-more-text {
    clear: both;
    text-decoration: none;
    display: block;
    color: #83abb7;
    font-family: Mukta;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
  }

  .export-link a {
    float: right;
    color: #83abb7;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.45px;
  }

  .align-left {
    text-align: left;
    color: #050505;

    div.location {
      margin: 0 0 20px 0;
    }

    .founded {
      margin: 0;

      strong {
        display: inline-block;
        padding-right: 10px;
        font-size: 16px;
        font-weight: normal;
      }
    }

    svg {
      padding: 0 10px 0 0;
    }

    div {
      margin: 0 0 30px 0;

      div {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.45px;
      }

      div.inline {
        display: inline;
      }
    }

    strong {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.5px;
    }

    .localizations div {
      display: block;
    }
  }

  .autocomplete-section {
    background-color: white;
    font-family: Mukta;
    border: 1px solid #f7f7f7;
    margin: 0 0 0 -20px !important;
    padding: 20px;

    .react-tags {
      position: relative;
      padding: 6px 0 0 6px;
      border: 1px solid #D1D1D1;
      border-radius: 1px;

      /* shared font styles */
      font-size: 1em;
      line-height: 1.2;

      /* clicking anywhere will focus the input */
      cursor: text;
    }

    .react-tags.is-focused {
      border-color: #B1B1B1;
    }

    .react-tags__selected {
      display: inline;
    }

    .react-tags__selected-tag {
      display: inline-block;
      box-sizing: border-box;
      margin: 0 6px 6px 0;
      padding: 5px 18px;
      border: none;
      border-radius: 2px;
      background: #F1F1F1;
      font-family: Mukta;

      /* match the font styles */
      font-size: inherit;
      line-height: inherit;
    }

    .react-tags__selected-tag:after {
      content: '\2715';
      color: #AAA;
      margin-left: 8px;
    }

    .react-tags__selected-tag:hover,
    .react-tags__selected-tag:focus {
      border-color: #B1B1B1;
    }

    .react-tags__search {
      display: inline-block;

      /* match tag layout */
      padding: 7px 2px;
      margin-bottom: 6px;

      /* prevent autoresize overflowing the container */
      max-width: 100%;
    }

    @media screen and (min-width: 30em) {

      .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
      }

    }

    .react-tags__search input {
      /* prevent autoresize overflowing the container */
      max-width: 100%;

      /* remove styles and layout from this element */
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;

      /* match the font styles */
      font-size: inherit;
      line-height: inherit;
    }

    .react-tags__search input::-ms-clear {
      display: none;
    }

    .react-tags__suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
    }

    @media screen and (min-width: 30em) {

      .react-tags__suggestions {
        width: 240px;
      }

    }

    .react-tags__suggestions ul {
      margin: 4px -1px;
      padding: 0;
      list-style: none;
      background: white;
      border: 1px solid #D1D1D1;
      border-radius: 2px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }

    .react-tags__suggestions li {
      border-bottom: 1px solid #ddd;
      padding: 6px 8px;
    }

    .react-tags__suggestions li mark {
      text-decoration: underline;
      background: none;
      font-weight: 600;
    }

    .react-tags__suggestions li:hover {
      cursor: pointer;
      background: #eee;
    }

    .react-tags__suggestions li.is-active {
      background: #b7cfe0;
    }

    .react-tags__suggestions li.is-disabled {
      opacity: 0.5;
      cursor: auto;
    }

    .react-tags {
      border: none;

      button {
        border-radius: 14px;
        background-color: #e9f2f4;
        cursor: pointer;
      }

      .react-tags__selected-tag:after {
        color: #b85767;
      }
    }
  }
}
