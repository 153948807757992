@import url('https://fonts.googleapis.com/css?family=Mukta');

.limit-belt {
  display: inline-block;
  width: 100%;
  height: 51px;
  background-color: #344865;

  .box {
    width: 600px;
    margin: 20px auto 0 auto;
    display: flex;

    .text {
      color: #fbfbfb;
      font-family: "Mukta";
      font-size: 16px;
      font-weight: 400;
      width: 200px;
      white-space: nowrap;
      margin: -5px 0 0 0;
    }

    a {
      color: #fbfbfb;
    }

    .meter {
      height: 9px;
      width: 300px;
      margin: 0 20px 0 0;
      position: relative;
      background: #bdbdbd;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);

      span {
        display: block;
        height: 100%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        //background-color: #4fba81;
        position: relative;
        overflow: hidden;
      }
    }
  }
}
