.user-form {
  font-family: Mukta;

  h3 {
    color: #e3dbdb;
    margin: 0 0 20px 0;
  }

  .form-group {
    text-align: left;
  }

  .field {
    margin: 0 0 17px 0;
    text-align: left;

    &__error {
      height: 15px;
    }

    .errors {
      padding: 0 0 8px 0;
    }
  }

  .form-error {
    margin-top: 10px;
  }

  .errors {
    color: #eb7070;
    text-align: left;
    font-size: 12px;
    padding: 0 0 23px 0;
    height: 15px;

    span {
      float: left;
      clear: left;
      display: inline
    }

    a {
      color: #eb7070;
    }
  }
}