.forget-password {
  font-family: Mukta;
  background-color: #344865;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &__form {
    margin-top: 23px;
  }

  .form-group {
    text-align: left;
  }

  .form-error {
    margin-top: 10px;
  }

  .field {
    margin: 0 0 17px 0;
    text-align: left;

    &__error {
      height: 15px;
    }
  }

  .errors {
    color: #eb7070;
    text-align: left;
    font-size: 12px;
    height: 15px;

    span {
      float: left;
      clear: left;
      display: inline
    }
  }

  h3 {
    color: white;
    margin-top: 20px;
  }

  p {
    width: 320px;
    display: block;
    color: #e3dbdb;
    line-height: 22px;
  }

  .success-info {
    text-align: left;
    width: 320px;
    margin-top: auto;
    margin-bottom: auto;

    h2 {
      color: white;
      line-height: 30px;
      margin-top: 48px;
      margin-bottom: 25px;
    }

    p {
      color: grey;
      line-height: 24px;
    }
  }
}
