@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=Mukta);
@import url(https://fonts.googleapis.com/css?family=Mukta);
html {
  height: 100%; }
  html body {
    margin: 0;
    height: 100%;
    font-family: -apple-system, Mukta, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    html body #root {
      height: 100%; }
    html body .react-tooltip-theme {
      background-color: #344865 !important;
      color: #fbfbfb !important;
      font-size: 12px !important;
      font-family: Mukta;
      max-width: 300px;
      padding: 7px 10px; }
      html body .react-tooltip-theme.place-top:after {
        border-top-color: #344865 !important;
        border-top-style: solid !important;
        border-top-width: 6px !important; }

.login-panel {
  text-align: center;
  width: 580px;
  margin: 200px auto;
  display: flex;
  justify-content: space-between; }
  .login-panel__logo {
    margin-top: 42px; }

.input-label {
  color: #e3dbdb;
  font-family: Mukta;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.4px;
  display: block;
  text-align: left; }

.input-box {
  position: relative; }
  .input-box::after {
    content: "";
    width: 0;
    position: absolute;
    height: 2px;
    left: 0;
    bottom: 3px;
    background-color: #eb7070;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease; }
  .input-box.error::after {
    width: 100%; }

.input-field {
  margin: 9px 0 3px 0;
  padding-bottom: 5px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: #e3dbdb 2px solid;
  background: transparent;
  width: 320px;
  color: white;
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  outline: none; }

.btn {
  font-family: Mukta;
  background-color: #c4b25b;
  font-size: 22px;
  padding: 6px 56px;
  max-width: 100%;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  margin: 0;
  border-radius: 24px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none; }
  .btn:hover {
    background-color: #dac76b; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

@charset "UTF-8";
.chat-history {
  width: 469px;
  margin: 0 20px 0 0;
  border-right: #e3dbdb 1px solid; }
  .chat-history ul.rc-pagination {
    position: relative;
    font-size: 38px;
    color: #83abb7;
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 50px; }
    .chat-history ul.rc-pagination li.rc-pagination-item {
      display: none; }
    .chat-history ul.rc-pagination li.rc-pagination-total-text {
      position: absolute;
      margin: 0 0 0 17%;
      width: 250px;
      padding: 0;
      color: #98b9c3;
      font-family: Mukta;
      font-size: 14px;
      font-weight: 400;
      line-height: 44px; }
    .chat-history ul.rc-pagination li:last-child {
      float: right; }
    .chat-history ul.rc-pagination .rc-pagination-prev {
      cursor: pointer;
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border: none;
      margin: 5px 0;
      float: left; }
      .chat-history ul.rc-pagination .rc-pagination-prev a:after {
        content: "‹";
        display: block; }
    .chat-history ul.rc-pagination .rc-pagination-next {
      cursor: pointer;
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border: none;
      margin: 5px 0; }
      .chat-history ul.rc-pagination .rc-pagination-next a:after {
        content: "›";
        display: block; }
    .chat-history ul.rc-pagination .rc-pagination-disabled .rc-pagination-jump-prev,
    .chat-history ul.rc-pagination .rc-pagination-disabled .rc-pagination-jump-next {
      pointer-events: none; }
    .chat-history ul.rc-pagination .rc-pagination-disabled a {
      color: #ccc;
      cursor: default; }
    .chat-history ul.rc-pagination .rc-pagination-disabled .rc-pagination-item,
    .chat-history ul.rc-pagination .rc-pagination-disabled .rc-pagination-prev,
    .chat-history ul.rc-pagination .rc-pagination-disabled .rc-pagination-next {
      cursor: not-allowed; }
    .chat-history ul.rc-pagination .rc-pagination-item-link {
      color: #83abb7;
      text-decoration: none; }

.company-item {
  height: 68px;
  background-color: #f7f7f7;
  margin: 0 0 5px 0;
  text-align: left;
  font-family: Mukta;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  position: relative; }
  .company-item svg {
    top: 55%;
    right: 5px;
    position: absolute;
    cursor: pointer;
    display: none; }
  .company-item:hover svg {
    display: block; }

.company-item:hover, .active {
  background: #e9f2f4; }

.company-item h6 {
  color: #050505;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  padding: 10px 25px 0 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.4em;
  white-space: nowrap; }

.company-item p {
  color: #050505;
  font-size: 16px;
  font-weight: 400;
  margin: 6px 0 0 32px;
  padding: 0 30px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.4em;
  white-space: nowrap; }

.export-link {
  display: block;
  height: 20px;
  margin: 0 0 10px 0;
  width: 100%;
  text-align: right; }

.export-link a {
  text-decoration: none;
  color: #83abb7;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.45px;
  padding: 0 10px 0 0; }

.profile .error-message {
  padding: 20px; }
  .profile .error-message strong {
    font-size: 24px; }
  .profile .error-message p {
    font-size: 18px; }

.company-profile {
  font-family: Mukta; }
  .company-profile svg .social-hover {
    fill: #83ABB7; }
  .company-profile svg:hover .social-hover {
    fill: #344865; }
  .company-profile strong {
    font-family: Mukta;
    margin: 0 0 20px 0;
    display: block; }
  .company-profile .logo {
    clear: both;
    min-height: 150px; }
  .company-profile .title {
    color: #050505;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px; }
  .company-profile .url {
    color: #bdbdbd;
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 20px 0; }
    .company-profile .url a {
      color: #bdbdbd; }
  .company-profile .socials {
    width: 100%;
    text-align: center;
    margin: 0 0 60px 0; }
    .company-profile .socials a {
      font-size: 33px;
      padding: 0 10px 0 0; }
  .company-profile ul.additional-info {
    list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAMAAABhq6zVAAAAAXNSR0IB2cksfwAAAIpQTFRFAAAATrqAT7qBAP//T7qBT7qAUryAT7qBUriFT7mAULqBT7mAT7qBT7uBT7yAT7qBTb+ATruCT7qBTriAT7uBULmBT7uCULqBVaqATLiAT7qBVbuIULqBULqBT7qCT7qBT7mBTriBUbyCT7qBVcaOTbiBT7qBUbyGT7qBULuBULuBT7qBRrmLT7l/f7bW7wAAAC50Uk5TABrlAZiRIu8ZOqCH12Eq7BQx7SSogHTKBjLpD7qGsXfnQTnmCU/jE7xtzeEL1iv/LesAAABaSURBVHicY2CAA0YmBJuZhRXBYWPnYOCESXBxMzDw8EI4fPwCDAyCQsJgCRFRECUmLgEkJaWkwWIyskBCTh5qvoIig5KyCtQgVTV1DU24FSxa2hJwjo4uxHwA5DQEQvJGtXoAAAAASUVORK5CYII=);
    font-family: Mukta;
    font-size: 18px;
    font-weight: 400;
    width: 130px;
    margin: 0;
    padding: 0 0 0 20px; }
    .company-profile ul.additional-info li {
      padding: 0 0 17px 0; }
      .company-profile ul.additional-info li a {
        color: #050505; }
  .company-profile .show-more-text {
    clear: both;
    text-decoration: none;
    display: block;
    color: #83abb7;
    font-family: Mukta;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer; }
  .company-profile .export-link a {
    float: right;
    color: #83abb7;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.45px; }
  .company-profile .align-left {
    text-align: left;
    color: #050505; }
    .company-profile .align-left div.location {
      margin: 0 0 20px 0; }
    .company-profile .align-left .founded {
      margin: 0; }
      .company-profile .align-left .founded strong {
        display: inline-block;
        padding-right: 10px;
        font-size: 16px;
        font-weight: normal; }
    .company-profile .align-left svg {
      padding: 0 10px 0 0; }
    .company-profile .align-left div {
      margin: 0 0 30px 0; }
      .company-profile .align-left div div {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.45px; }
      .company-profile .align-left div div.inline {
        display: inline; }
    .company-profile .align-left strong {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.5px; }
    .company-profile .align-left .localizations div {
      display: block; }
  .company-profile .autocomplete-section {
    background-color: white;
    font-family: Mukta;
    border: 1px solid #f7f7f7;
    margin: 0 0 0 -20px !important;
    padding: 20px; }
    .company-profile .autocomplete-section .react-tags {
      position: relative;
      padding: 6px 0 0 6px;
      border: 1px solid #D1D1D1;
      border-radius: 1px;
      /* shared font styles */
      font-size: 1em;
      line-height: 1.2;
      /* clicking anywhere will focus the input */
      cursor: text; }
    .company-profile .autocomplete-section .react-tags.is-focused {
      border-color: #B1B1B1; }
    .company-profile .autocomplete-section .react-tags__selected {
      display: inline; }
    .company-profile .autocomplete-section .react-tags__selected-tag {
      display: inline-block;
      box-sizing: border-box;
      margin: 0 6px 6px 0;
      padding: 5px 18px;
      border: none;
      border-radius: 2px;
      background: #F1F1F1;
      font-family: Mukta;
      /* match the font styles */
      font-size: inherit;
      line-height: inherit; }
    .company-profile .autocomplete-section .react-tags__selected-tag:after {
      content: '\2715';
      color: #AAA;
      margin-left: 8px; }
    .company-profile .autocomplete-section .react-tags__selected-tag:hover,
    .company-profile .autocomplete-section .react-tags__selected-tag:focus {
      border-color: #B1B1B1; }
    .company-profile .autocomplete-section .react-tags__search {
      display: inline-block;
      /* match tag layout */
      padding: 7px 2px;
      margin-bottom: 6px;
      /* prevent autoresize overflowing the container */
      max-width: 100%; }
    @media screen and (min-width: 30em) {
      .company-profile .autocomplete-section .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative; } }
    .company-profile .autocomplete-section .react-tags__search input {
      /* prevent autoresize overflowing the container */
      max-width: 100%;
      /* remove styles and layout from this element */
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;
      /* match the font styles */
      font-size: inherit;
      line-height: inherit; }
    .company-profile .autocomplete-section .react-tags__search input::-ms-clear {
      display: none; }
    .company-profile .autocomplete-section .react-tags__suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%; }
    @media screen and (min-width: 30em) {
      .company-profile .autocomplete-section .react-tags__suggestions {
        width: 240px; } }
    .company-profile .autocomplete-section .react-tags__suggestions ul {
      margin: 4px -1px;
      padding: 0;
      list-style: none;
      background: white;
      border: 1px solid #D1D1D1;
      border-radius: 2px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }
    .company-profile .autocomplete-section .react-tags__suggestions li {
      border-bottom: 1px solid #ddd;
      padding: 6px 8px; }
    .company-profile .autocomplete-section .react-tags__suggestions li mark {
      text-decoration: underline;
      background: none;
      font-weight: 600; }
    .company-profile .autocomplete-section .react-tags__suggestions li:hover {
      cursor: pointer;
      background: #eee; }
    .company-profile .autocomplete-section .react-tags__suggestions li.is-active {
      background: #b7cfe0; }
    .company-profile .autocomplete-section .react-tags__suggestions li.is-disabled {
      opacity: 0.5;
      cursor: auto; }
    .company-profile .autocomplete-section .react-tags {
      border: none; }
      .company-profile .autocomplete-section .react-tags button {
        border-radius: 14px;
        background-color: #e9f2f4;
        cursor: pointer; }
      .company-profile .autocomplete-section .react-tags .react-tags__selected-tag:after {
        color: #b85767; }

.tags span {
  display: inline-block;
  margin-right: 5px; }

.App-body {
  background-color: #fbfbfb;
  min-height: 100%;
  height: auto;
  position: relative; }
  .App-body .App {
    text-align: center;
    width: 1180px;
    margin: 0 auto;
    padding: 0 0 40px 0; }
    .App-body .App .right-panel {
      width: 691px;
      display: block; }
  .App-body .App-header {
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: 120px; }
    .App-body .App-header .user-section {
      float: right;
      color: #344865;
      font-family: "Mukta";
      font-size: 14px;
      font-weight: 400;
      margin: 56px 0 0 0; }
      .App-body .App-header .user-section a.logout {
        width: 36px;
        height: 25px;
        display: inline-block;
        margin: 0;
        position: relative;
        text-align: left; }
      .App-body .App-header .user-section ul {
        display: inline-block;
        padding: 0 20px 0 0;
        margin: 0; }
        .App-body .App-header .user-section ul a {
          color: #344865; }
        .App-body .App-header .user-section ul li {
          display: inline-block;
          padding: 0 10px; }
      .App-body .App-header .user-section span {
        margin: 0 16px 0 0;
        display: inline-block;
        margin: 4px 10px 0 0; }
      .App-body .App-header .user-section img {
        position: absolute;
        top: 5px; }
  .App-body .App-link {
    color: #09d3ac; }
  .App-body .body {
    display: flex;
    flex-wrap: wrap;
    min-height: 200px; }
  .App-body .first-section {
    width: 100%;
    text-align: right; }
  .App-body .second-section {
    display: flex; }
  .App-body img.logo {
    margin: 30px 0 0 0;
    float: left; }
  .App-body .dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none; }

.react-autosuggest__input {
  width: 330px;
  border: 0;
  border-bottom: #e3dbdb solid 1px;
  background-color: #fbfbfb;
  height: 30px;
  padding: 10px 20px;
  font-family: Mukta;
  font-size: 18px;
  font-style: italic;
  padding-right: 70px; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 440px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd; }

.suggestion-item {
  text-align: left; }
  .suggestion-item small {
    clear: both;
    display: block; }

.search-bar {
  width: 100%;
  height: 50px;
  position: relative;
  margin: 50px 0 80px 0; }
  .search-bar .bar {
    display: flex;
    position: absolute;
    left: 30%;
    bottom: 5px; }
  .search-bar button {
    width: 156px;
    height: 49px;
    border-radius: 24px;
    background-color: #83abb7;
    font-size: 18px;
    margin: 0 0 0 -2px;
    border: none;
    color: white;
    cursor: pointer; }
    .search-bar button:hover {
      background-color: #94c4d2; }
  .search-bar input {
    color: #bdbdbd; }
  .search-bar .search-instruction {
    top: 100px;
    text-align: left;
    width: 100%;
    font-size: 20px;
    font-family: Mukta;
    color: #050505;
    display: inline-block; }
    .search-bar .search-instruction svg {
      margin: 45px 0 30px 295px; }
    .search-bar .search-instruction div {
      width: 400px;
      display: inline-block;
      margin: 0 0 0 25px;
      font-style: italic; }
  .search-bar .error .react-autosuggest__input {
    border-bottom: 1px solid #eb7070; }
  .search-bar .errors {
    display: flex;
    position: absolute;
    left: 30%;
    bottom: -5px;
    color: #eb7070;
    text-align: left;
    font-size: 12px;
    font-style: italic; }

.modal-list a.open-modal {
  color: #83abb7;
  font-size: 18px;
  font-weight: 700;
  padding: 0 10px 0 0;
  line-height: 50px;
  margin: 0 0 0 -70px;
  font-family: Mukta; }

.Modal {
  position: absolute;
  width: 450px;
  min-height: 380px;
  height: auto;
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  padding: 0 10px;
  background-color: #F7F7F7;
  border: 1px solid #E9F2F4;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  overflow: hidden;
  font-family: Mukta; }
  .Modal h3 {
    color: #050505;
    font-size: 24px; }
  .Modal textarea {
    width: 95%;
    height: 200px;
    background: #ffffff;
    font-family: Mukta;
    color: #BDBDBD;
    border: none;
    padding: 10px; }
  .Modal .buttons {
    text-align: right;
    margin: 20px 0 20px 0; }
    .Modal .buttons a {
      cursor: pointer;
      text-decoration: underline;
      color: #bdbdbd; }
  .Modal button {
    width: 100px;
    height: 35px;
    margin: 0 0 0 10px;
    border-radius: 24px;
    background-color: #83abb7;
    font-size: 18px;
    border: none;
    color: white;
    cursor: pointer; }
    .Modal button:disabled {
      background-color: #bdbdbd; }
  .Modal span {
    display: block; }
  .Modal .errors {
    color: #B85767;
    font-size: 14px; }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none; }

.navigation {
  width: 690px;
  height: 20px;
  float: right; }
  .navigation button {
    cursor: pointer;
    border: none;
    background: none; }
    .navigation button:disabled {
      cursor: default !important; }
      .navigation button:disabled svg path {
        fill: #cccccc; }
    .navigation button svg path {
      fill: #83ABB7; }
  .navigation .left-arrow {
    position: relative;
    width: 9px;
    height: 17px;
    float: left; }
  .navigation .right-arrow {
    position: relative;
    width: 9px;
    height: 17px;
    float: right; }

.limit-belt {
  display: inline-block;
  width: 100%;
  height: 51px;
  background-color: #344865; }
  .limit-belt .box {
    width: 600px;
    margin: 20px auto 0 auto;
    display: flex; }
    .limit-belt .box .text {
      color: #fbfbfb;
      font-family: "Mukta";
      font-size: 16px;
      font-weight: 400;
      width: 200px;
      white-space: nowrap;
      margin: -5px 0 0 0; }
    .limit-belt .box a {
      color: #fbfbfb; }
    .limit-belt .box .meter {
      height: 9px;
      width: 300px;
      margin: 0 20px 0 0;
      position: relative;
      background: #bdbdbd;
      border-radius: 5px;
      box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3); }
      .limit-belt .box .meter span {
        display: block;
        height: 100%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        position: relative;
        overflow: hidden; }

.login {
  background-color: #344865;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }
  .login__body--content {
    display: flex;
    flex-wrap: wrap;
    width: 322px; }
    .login__body--content__links {
      margin: 20px 0 0 0;
      width: 100%;
      text-align: left; }
      .login__body--content__links a.forgot-password {
        flex-grow: 3;
        margin: 0;
        color: #e3dbdb;
        text-decoration: underline; }
      .login__body--content__links a.register-link {
        flex-grow: 3;
        margin: 0 0 0 10px;
        color: #e3dbdb;
        text-decoration: underline; }
  .login .break {
    flex-basis: 100%;
    width: 0px;
    height: 0px;
    overflow: hidden; }

.user-form {
  font-family: Mukta; }
  .user-form h3 {
    color: #e3dbdb;
    margin: 0 0 20px 0; }
  .user-form .form-group {
    text-align: left; }
  .user-form .field {
    margin: 0 0 17px 0;
    text-align: left; }
    .user-form .field__error {
      height: 15px; }
    .user-form .field .errors {
      padding: 0 0 8px 0; }
  .user-form .form-error {
    margin-top: 10px; }
  .user-form .errors {
    color: #eb7070;
    text-align: left;
    font-size: 12px;
    padding: 0 0 23px 0;
    height: 15px; }
    .user-form .errors span {
      float: left;
      clear: left;
      display: inline; }
    .user-form .errors a {
      color: #eb7070; }

.register {
  background-color: #344865;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }
  .register .success-info {
    text-align: left;
    width: 340px;
    height: 193px;
    margin-top: auto;
    margin-bottom: auto; }
    .register .success-info h2 {
      color: white;
      margin-top: 21px;
      margin-bottom: 25px; }
    .register .success-info p {
      color: grey; }
  .register .user-form__terms {
    display: block;
    color: #e3dbdb;
    font-family: Mukta;
    font-size: 15px;
    margin: 19px 0 0 0; }
    .register .user-form__terms a {
      color: #e3dbdb;
      font-style: italic;
      margin-left: 4px;
      outline: none;
      display: inline-block; }

.forget-password {
  font-family: Mukta;
  background-color: #344865;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }
  .forget-password__form {
    margin-top: 23px; }
  .forget-password .form-group {
    text-align: left; }
  .forget-password .form-error {
    margin-top: 10px; }
  .forget-password .field {
    margin: 0 0 17px 0;
    text-align: left; }
    .forget-password .field__error {
      height: 15px; }
  .forget-password .errors {
    color: #eb7070;
    text-align: left;
    font-size: 12px;
    height: 15px; }
    .forget-password .errors span {
      float: left;
      clear: left;
      display: inline; }
  .forget-password h3 {
    color: white;
    margin-top: 20px; }
  .forget-password p {
    width: 320px;
    display: block;
    color: #e3dbdb;
    line-height: 22px; }
  .forget-password .success-info {
    text-align: left;
    width: 320px;
    margin-top: auto;
    margin-bottom: auto; }
    .forget-password .success-info h2 {
      color: white;
      line-height: 30px;
      margin-top: 48px;
      margin-bottom: 25px; }
    .forget-password .success-info p {
      color: grey;
      line-height: 24px; }

.new-password {
  font-family: Mukta;
  background-color: #344865;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }
  .new-password .body {
    text-align: center;
    width: 600px;
    margin: 200px auto;
    display: flex;
    flex-flow: row wrap; }
  .new-password .forget-password__form {
    margin-top: 52px; }
  .new-password .field {
    margin: 0 0 17px 0;
    text-align: left; }
    .new-password .field__error {
      height: 15px; }
      .new-password .field__error .errors {
        color: #eb7070;
        text-align: left;
        font-size: 12px;
        padding: 0 0 8px 0;
        height: 15px; }
  .new-password .form-group {
    text-align: left;
    margin-top: 42px; }
  .new-password h3 {
    color: white; }
  .new-password p {
    width: 320px;
    display: block;
    color: #e3dbdb; }
  .new-password .success-info {
    text-align: left;
    width: 320px;
    margin-top: auto;
    margin-bottom: auto; }
    .new-password .success-info h2 {
      color: white;
      line-height: 30px;
      margin-top: 48px;
      margin-bottom: 25px; }
    .new-password .success-info p {
      color: grey;
      line-height: 24px; }

.Accounts table {
  border: solid 1px #DDEEEE;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 13px Mukta, sans-serif;
  margin: 50px auto; }
  .Accounts table thead th {
    background-color: #83abb7;
    border: solid 1px #83abb7;
    color: white;
    padding: 10px;
    text-align: left; }
  .Accounts table tbody td {
    border: solid 1px #DDEEEE;
    color: #333;
    padding: 10px;
    text-shadow: 1px 1px 1px #fff; }

.admin-history .admin-history-wrapper {
  min-width: 1368px; }
  .admin-history .admin-history-wrapper .ReactTable {
    margin: 50px 0 0 0; }

.apikeys .title {
  color: #050505;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px; }

.apikeys .apikey {
  padding: 20px; }

body {
    margin: 0;
    height: 100%;
    background-color: #fbfbfb;
    font-family: Mukta;
}

#root {
    height: 100%;
}

.internalServer{
    width: 800px;
    margin: 50px auto;

    font-size: 24px;
    text-align: center;
    color: black;
}

.internalServer img{
    display: block;
    margin: 0 auto 50px auto;
}

.internalServer p{
    font-size: 18px;
}

